<template>
  <div
    class="d-flex justify-content-center align-items-center shadow-lg border-primary bg-black"
    style="border-radius: 10px;"
  >
    <b-button
      variant="primary"
      v-if="playerIsMuted"
      @click="unmute"
      id="btn-unmute"
      ><font-awesome-icon :icon="['fal', 'volume-slash']" /> Unmute</b-button
    >
    <video
      ref="videoPlayer"
      class="video-js vjs-fluid vjs-16-9"
      width="auto"
      height="auto"
    ></video>
  </div>
</template>

<script>
import "./videojs/skins/shaka/videojs.css";
import videojs from "video.js";
import './videojs/components/nuevo'
import './videojs/components/offline'
import './videojs/components/videojs.events'
import { BButton } from "bootstrap-vue";

export default {
  name: "VideoPlayer",
  components: {
    BButton,
  },
  props: {
      title: {
          type: String,
          required: false,
          default: () => ''
      },
      videoId: {
          type: String,
          required: false,
          default: () => ''
      },
    options: {
      type: Object,
      default() {
        return {
          controls: true,
            preload: "auto",
            playsinline: true,
          poster: this.$store.state.event.event.meta.videoBanner || "",
          sources: [],
        };
      },
    },
    url: {
      type: String,
      default() {
        return "";
      },
    },
    poster: {
      type: String,
      default() {
        return "";
      }
    },
    slideImage: {
      type: String,
      default() {
        return "";
      }
    }
  },
  data() {
    return {
      player: null,
      playerIsMuted: false,
    };
  },
  computed: {
    posterImage() {
        return this.$store.state.event.event.meta.videoBanner || ''
    },
    posterImageOffline() {
        return this.$store.state.event.event.meta.videoBannerOffline || ''
    },
    playerOptions() {
      let _options = this.options;
      // console.log('this.options', this.options, this.poster, this.slideImage)
      if ((!this.options || this.options.sources.length === 0) && this.url) {
        _options = {
            controls: true,
            preload: "auto",
            playsinline: true,
            sources: [{ type: "video/mp4", src: this.url + "#t=0.1" }],
            poster: this.poster,
            slideImage: this.slideImage,
        };
      }
      return _options;
    },
  },
  methods: {
    unmute() {
      if (this.player) {
        this.player.muted(false);
        this.playerIsMuted = false;
      }
    },
  },
  mounted() {
    const videojsOptions = this.playerOptions;

    const nuevoOptions = {
        singlePlay:true,
        contextMenu: false,
        shareMenu: false,
        zoomMenu: false,
        settingsButton: true,
        videoInfo: true,
        title: this.title,
        video_id: this.videoId,
        offlineImage: this.posterImageOffline,
        slideImage: this.slideImage
    //   logo: "//url-to-logo-image.png",
    //   logourl: "//url-to-go-on-logo-click.com",
    };
    // console.log('nuevoOptions', nuevoOptions)

    this.player = videojs(
      this.$refs.videoPlayer,
      videojsOptions,
      function onPlayerReady() {
        console.log("onPlayerReady");
      }
    );

    this.player.nuevo(nuevoOptions);

    // Initialize Offline plugin
    this.player.offline({ 
        offlineImage: this.posterImageOffline,
        offlineTimeout: 2,
        offlineCountdown: false,
    })

    // Initialize Events plugin
    this.player.events({ analytics:true });
    this.player.on("track", (e, data) => {
        // console.log('tracking', data)
        this.$store.dispatch('sessions/trackEvent', data)
        // switch (data.event) {
        //     case 'firstPlay': console.log( 'First Play'); break;
        //     case '25%': console.log( 'Progress 25%'); break;
        //     case '50%': console.log( 'Progress 50%'); break;
        //     case '75%': console.log( 'Progress 75%'); break;
        //     case 'ended': console.log( 'Video completed'); break;
        // }
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
<style>
.container {
   width:100%;
   max-width:800px;
   margin:0 auto;
}
.vjs-control-bar {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
/* .vjs-play-control button {
        border-bottom-left-radius: 20px !important;
    } */
.video-js video,
.vjs-poster {
  border-radius: 10px;
}
.video-js.vjs-16-9 {
  padding-top: 56.25%;
  position: relative;
  width: 100%;
  border-radius: 10px;
}
.video-js.vjs-16-9>div {
	position: absolute;
}
#btn-unmute {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 99;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>