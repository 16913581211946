var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"text-muted"},[_vm._v("All fields are required.")]),_c('validation-observer',{ref:"meetingRequestForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.sendMeetingRequest($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Meeting Title *","label-for":"meeting-title"}},[_c('validation-provider',{attrs:{"name":"Meeting Title","vid":"meetingtitle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"meeting-title","state":errors.length > 0 ? false : null,"name":"register-title","placeholder":"Enter a subject for the meeting request"},model:{value:(_vm.meeting.title),callback:function ($$v) {_vm.$set(_vm.meeting, "title", $$v)},expression:"meeting.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Start Date","label-for":"meeting-startdate"}},[_c('validation-provider',{attrs:{"name":"Start Date","vid":"startdate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"meeting-startdate","state":errors.length > 0 ? false : null,"name":"meeting-startdate","config":_vm.dateConfig},model:{value:(_vm.meeting.startdate),callback:function ($$v) {_vm.$set(_vm.meeting, "startdate", $$v)},expression:"meeting.startdate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Duration *","label-for":"meeting-duration"}},[_c('validation-provider',{attrs:{"name":"Duration","vid":"duration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"meeting-duration","label":"duration","options":[
                    { value: null, text: '' },
                    { value: '15 mins', text: '15 minutes' },
                    { value: '30 mins', text: '30 minutes' },
                    { value: '1 hour', text: '1 hour' },
                    { value: 'more than 1 hour', text: 'Longer' } ],"name":"register-duration"},model:{value:(_vm.meeting.duration),callback:function ($$v) {_vm.$set(_vm.meeting, "duration", $$v)},expression:"meeting.duration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Message *","label-for":"meeting-message"}},[_c('validation-provider',{attrs:{"name":"Message","vid":"message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"meeting-message","label":"message","name":"register-message","rows":"5","placeholder":"Enter a brief message describing the purpose of the meeting"},model:{value:(_vm.meeting.message),callback:function ($$v) {_vm.$set(_vm.meeting, "message", $$v)},expression:"meeting.message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-button',{staticClass:"float-right",attrs:{"type":"submit","variant":"primary","disabled":invalid}},[_vm._v(" Send Request ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }