<template>
    <b-row class="mt-2">
      <b-col md="8" lg="9">
        <b-card>
          <b-row>
            <b-col xs="12" md="8">
              <b-img :src="exhibitor.banner" :alt="exhibitor.name" fluid class="rounded-lg"/>
            </b-col>
            <b-col xs="12" md="4" class="mt-2 mt-md-0">
              <b-img :src="exhibitor.logo" :alt="exhibitor.name" fluid center/>
              <h2>{{ exhibitor.name }}</h2>
              <h4>{{ exhibitor.tagline }}</h4>
              <p v-if="exhibitor.shortDescription">{{ exhibitor.shortDescription }}</p>
              <b-link :href="exhibitor.contact.website" class="font-weight-bold" target="_blank">Discover more <feather-icon icon="ArrowRightIcon" /></b-link>
            </b-col>
          </b-row>
        </b-card>
        <div class="d-inline d-md-none">
          <b-button
          variant="primary"
          block
           v-b-modal.modal-dropcard
        >
          <h4 class="text-white m-0">Share Business Card</h4>
        </b-button>

         <b-modal
          id="modal-dropcard"
          centered
          title="Share Business Card"
          size="md"
          footer-class="d-none"
        >
         <p>
						Your contact information will be shared with {{exhibitor.name}}. Click on the button below to continue.
					</p>
					<b-button
						@click="shareBusinessCard"
						variant="primary"
						size="sm"
					>
						<font-awesome-icon :icon="['fal', 'address-card']" size="lg" />
						&nbsp;Share Business Card
					</b-button>
        </b-modal>


        <span class="d-block mt-1" tabindex="0" v-b-tooltip.hover.v-warning="'No executive is available. Try sending a meeting request'">
          <b-button
            variant="primary"
            block
            @click="startChat"
            disabled
          >
            <h4 class="text-white m-0">Start A Conversation</h4>
          </b-button>
        </span>

        <span class="d-block mt-1" tabindex="0">
          <b-button
            variant="primary"
            block
            v-b-modal.modal-meetingrequest
          >
            <h4 class="text-white m-0">Request A Meeting</h4>
          </b-button>
        </span>

         <b-modal
          id="modal-meetingrequest"
          centered
          title="Request a Meeting"
          size="lg"
          footer-class="d-none"
        >
          <meeting-request :meetingRequestedWithId="exhibitor.slug" @closeMeetingRequestModal="closeMeetingRequestModal"/>
        </b-modal>

        <b-card title="Contact Details" variant="primary" class="mt-1">
          <strong>{{ exhibitor.name }}</strong><br/>
          <span v-if="exhibitor.contact.address">{{exhibitor.contact.address}}<br/><br/></span>
          <span v-if="exhibitor.contact.contactPerson">{{exhibitor.contact.contactPerson}}<br/></span>
          <span v-if="exhibitor.contact.jobTitle">{{exhibitor.contact.jobTitle}}<br/></span>
          <span v-if="exhibitor.contact.phone">{{exhibitor.contact.phone}}<br/></span>
          <span v-if="exhibitor.contact.email"><b-link :href="'mailto:' + exhibitor.contact.email" target="_blank">{{exhibitor.contact.email}}</b-link><br/></span>
          <span v-if="exhibitor.contact.website"><b-link :href="exhibitor.contact.website" target="_blank">{{exhibitor.contact.website}}</b-link><br/></span>
          <div>
            <b-button
              v-if="exhibitor.contact.social.linkedin"
              variant="linkedin"
              :href="exhibitor.contact.social.linkedin"
              target="_blank"
              class="mr-1 mt-1 p-1"
            >
              <feather-icon icon="LinkedinIcon" size="20"/>
            </b-button>
            <b-button
              v-if="exhibitor.contact.social.facebook"
              variant="facebook"
              :href="exhibitor.contact.social.facebook"
              target="_blank"
              class="mr-1 mt-1 p-1"
            >
              <feather-icon icon="FacebookIcon" size="20"/>
            </b-button>
            <b-button
              v-if="exhibitor.contact.social.twitter"
              variant="twitter"
              :href="exhibitor.contact.social.twitter"
              target="_blank"
              class="mr-1 mt-1 p-1"
            >
              <feather-icon icon="TwitterIcon" size="20"/>
            </b-button>
            <b-button
              v-if="exhibitor.contact.social.youtube"
              variant="google"
              :href="exhibitor.contact.social.youtube"
              target="_blank"
              class="mr-1 mt-1 p-1"
            >
              <feather-icon icon="YoutubeIcon" size="20" />
            </b-button>
            <b-button
              v-if="exhibitor.contact.social.instagram"
              variant="facebook"
              :href="exhibitor.contact.social.instagram"
              target="_blank"
              class="mr-1 mt-1 p-1"
            >
              <feather-icon icon="InstagramIcon" size="20"/>
            </b-button>
          </div>
        </b-card>
        </div>
        <b-card :title="'About ' + exhibitor.name">
          <div v-html="exhibitor.about" />
        </b-card>
        <div v-for="(asset, index) of Object.values(exhibitor.assets)" :key="index">
          <b-card v-if="asset.type === 'brochure' && asset.items.length>0" :title="asset.title">
            <b-row class="match-height">
              <b-col xs="12" lg="6" xl="4" v-for="(brochure, index) in asset.items" :key="index">
                <b-link :href="brochure.url" target="_blank">
                  <b-media no-body class="d-flex align-items-center">
                    <b-media-aside class="mr-1">
                      <b-avatar
                        rounded
                        variant="light-primary"
                        size="34"
                      >
                        <feather-icon
                          icon="FileIcon"
                          size="18"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="w-100">
                      <h6 class="m-0">
                        {{ brochure.title }}
                      </h6>
                      <p class="small">View <feather-icon icon="ArrowRightIcon" /></p>
                    </b-media-body>
                  </b-media>
                </b-link>
              </b-col>
            </b-row>
          </b-card>

          <b-card v-if="asset.type === 'video' && asset.items.length>0" :title="asset.title">
            <b-row>
              <b-col xs="12" xl="6" v-for="(video, index) in asset.items" :key="index">
                <b-media no-body class="d-flex align-items-center">
                  <b-media-body class="w-100">
                    <LazyYoutubeVideo v-if="video.url.indexOf('youtube.com')>0" :src="video.url" :autoplay="video.autoPlay" :previewImageSize="video.previewSize || 'maxresdefault'" />
                    <VideoPlayer v-if="video.url.indexOf('youtube.com')<0" :url="video.url" :autoplay="video.autoPlay" :title="video.title" :poster="video.poster || ''" :slideImage="video.sprite || ''" />
                    <h6 class="m-0 mt-1">
                      {{ video.title }}
                    </h6>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card>

          <b-card v-if="asset.type === 'product' && asset.items.length>0" :title="asset.title">
            <b-row class="match-height">
              <b-col xs="12" xl="6" v-for="(product, index) in asset.items" :key="index">
                <b-media no-body class="d-flex align-items-center mb-2">
                  <b-media-aside class="mr-1 ">
                    <b-img :src="product.image" left width="150px" />
                  </b-media-aside>
                  <b-media-body class="w-100">
                    <h3 class="mb-1">{{ product.title }}</h3>
                    <p><strong>{{ product.subTitle }}</strong></p>
                    <div v-html="product.description" />
                    <b-button
                      v-if="product.link"
                      variant="primary"
                      :href="product.link"
                      target="_blank"
                    >
                      {{ product.linkText }} 
                      <feather-icon icon="ArrowRightIcon" />
                    </b-button>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-col>
      <b-col md="4" lg="3" d-none d-md-inline>
        <b-button
          variant="primary"
          block
           v-b-modal.modal-dropcard
        >
          <h4 class="text-white m-0">Share Business Card</h4>
        </b-button>

         <b-modal
          id="modal-dropcard"
          centered
          title="Share Business Card"
          size="md"
          footer-class="d-none"
        >
         <p>
						Your contact information will be shared with {{exhibitor.name}}. Click on the button below to continue.
					</p>
					<b-button
						@click="shareBusinessCard"
						variant="primary"
						size="sm"
					>
						<font-awesome-icon :icon="['fal', 'address-card']" size="lg" />
						&nbsp;Share Business Card
					</b-button>
        </b-modal>


        <span class="d-block mt-1" tabindex="0" v-b-tooltip.hover.v-warning="'No executive is available. Try sending a meeting request'">
          <b-button
            variant="primary"
            block
            @click="startChat"
            disabled
          >
            <h4 class="text-white m-0">Start A Conversation</h4>
          </b-button>
        </span>

        <span class="d-block mt-1" tabindex="0">
          <b-button
            variant="primary"
            block
            v-b-modal.modal-meetingrequest
          >
            <h4 class="text-white m-0">Request A Meeting</h4>
          </b-button>
        </span>

         <b-modal
          id="modal-meetingrequest"
          centered
          title="Request a Meeting"
          size="lg"
          footer-class="d-none"
        >
          <meeting-request :meetingRequestedWithId="exhibitor.slug" @closeMeetingRequestModal="closeMeetingRequestModal"/>
        </b-modal>

        <b-card title="Contact Details" variant="primary" class="mt-1">
          <strong>{{ exhibitor.name }}</strong><br/>
          <span v-if="exhibitor.contact.address">{{exhibitor.contact.address}}<br/><br/></span>
          <span v-if="exhibitor.contact.contactPerson">{{exhibitor.contact.contactPerson}}<br/></span>
          <span v-if="exhibitor.contact.jobTitle">{{exhibitor.contact.jobTitle}}<br/></span>
          <span v-if="exhibitor.contact.phone">{{exhibitor.contact.phone}}<br/></span>
          <span v-if="exhibitor.contact.email"><b-link :href="'mailto:' + exhibitor.contact.email" target="_blank">{{exhibitor.contact.email}}</b-link><br/></span>
          <span v-if="exhibitor.contact.website"><b-link :href="exhibitor.contact.website" target="_blank">{{exhibitor.contact.website}}</b-link><br/></span>
          <div>
            <b-button
              v-if="exhibitor.contact.social.linkedin"
              variant="linkedin"
              :href="exhibitor.contact.social.linkedin"
              target="_blank"
              class="mr-1 mt-1 p-1"
            >
              <feather-icon icon="LinkedinIcon" size="20"/>
            </b-button>
            <b-button
              v-if="exhibitor.contact.social.facebook"
              variant="facebook"
              :href="exhibitor.contact.social.facebook"
              target="_blank"
              class="mr-1 mt-1 p-1"
            >
              <feather-icon icon="FacebookIcon" size="20"/>
            </b-button>
            <b-button
              v-if="exhibitor.contact.social.twitter"
              variant="twitter"
              :href="exhibitor.contact.social.twitter"
              target="_blank"
              class="mr-1 mt-1 p-1"
            >
              <feather-icon icon="TwitterIcon" size="20"/>
            </b-button>
            <b-button
              v-if="exhibitor.contact.social.youtube"
              variant="google"
              :href="exhibitor.contact.social.youtube"
              target="_blank"
              class="mr-1 mt-1 p-1"
            >
              <feather-icon icon="YoutubeIcon" size="20" />
            </b-button>
            <b-button
              v-if="exhibitor.contact.social.instagram"
              variant="facebook"
              :href="exhibitor.contact.social.instagram"
              target="_blank"
              class="mr-1 mt-1 p-1"
            >
              <feather-icon icon="InstagramIcon" size="20"/>
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
</template>

<script>
import {
  BCard, BImg, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody, BButton, BRow, BCol, BLink, VBPopover, VBModal
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import VideoPlayer from "@/layouts/components/video-player/NuevoVideoPlayer.vue"
import MeetingRequest from '@/layouts/components/MeetingRequest.vue'
import LazyYoutubeVideo from 'vue-lazy-youtube-video'
import 'vue-lazy-youtube-video/dist/style.css'
import store from '@/store'

export default {
  metaInfo() {
    return {
      title: this.exhibitor.title
    }
  },
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    BButton,
    BRow,
    BCol,
    BLink,
    VBPopover,
    FeatherIcon,
    LazyYoutubeVideo,
    VideoPlayer,
    MeetingRequest,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  computed: {
    exhibitor() {
      return store.getters['exhibitors/bySlug'](this.$route.params.slug)
    }
  },
  methods: {
    startChat() {
      // TODO: implement exhibitor chat
    },
    closeMeetingRequestModal() {
      this.$bvModal.hide('modal-meetingrequest')
    },
    shareBusinessCard() {
      store.dispatch('exhibitors/dropcard', {
        fromBookingId: store.state.account.user.bookingNumber,
			  eventExhibitId: this.exhibitor.slug,
			  eventId: store.state.event.event.id
      }).then((response) => {
        this.$bvModal.hide('modal-dropcard')
        this.$toast({
            component: ToastificationContent,
            position: 'bottom-left',
            props: {
              title: `Share Business Card`,
              icon: 'UsersIcon',
              variant: 'success',
              text: `Your bussiness card has been shared with ${this.exhibitor.name}`,
            },
          })
      }).catch((error) => {
        this.$toast({
            component: ToastificationContent,
            props: {
              title: `Share Business Card`,
              icon: 'StopIcon',
              variant: 'error',
              text: `There was an error sharing your business card with ${this.exhibitor.name}. Please try again.`,
              position: 'bottom-left',
            },
        })
        this.showRegisterModal()
      })
    }
  },

}
</script>

<style scoped>

</style>
