<template>
  <div>
    <p class="text-muted">All fields are required.</p>
    <validation-observer ref="meetingRequestForm" #default="{ invalid }">
      <b-form class="mt-2" @submit.prevent="sendMeetingRequest">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Meeting Title *" label-for="meeting-title">
              <validation-provider
                #default="{ errors }"
                name="Meeting Title"
                vid="meetingtitle"
                rules="required"
              >
                <b-form-input
                  id="meeting-title"
                  v-model="meeting.title"
                  :state="errors.length > 0 ? false : null"
                  name="register-title"
                  placeholder="Enter a subject for the meeting request"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Start Date" label-for="meeting-startdate">
              <validation-provider
                #default="{ errors }"
                name="Start Date"
                vid="startdate"
                rules="required"
              >
                <flat-pickr
                  id="meeting-startdate"
                  v-model="meeting.startdate"
                  :state="errors.length > 0 ? false : null"
                  name="meeting-startdate"
                  class="form-control"
                  :config="dateConfig"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col cols="12" md="4">
            <b-form-group label="Start Time" label-for="meeting-starttime">
              <validation-provider
                #default="{ errors }"
                name="Start Time"
                vid="starttime"
                rules="required"
              >
                <flat-pickr
                  id="meeting-starttime"
                  v-model="meeting.starttime"
                  :state="errors.length > 0 ? false : null"
                  name="meeting-starttime"
                  :config="{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'H:i',
                  }"
                   class="form-control"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col cols="12" md="6">
            <b-form-group label="Duration *" label-for="meeting-duration">
                <validation-provider
                  #default="{ errors }"
                  name="Duration"
                  vid="duration"
                  rules="required"
                >
                  <b-form-select
                    id="meeting-duration"
                    v-model="meeting.duration"
                    label="duration"
                    :options="[
                      { value: null, text: '' },
                      { value: '15 mins', text: '15 minutes' },
                      { value: '30 mins', text: '30 minutes' },
                      { value: '1 hour', text: '1 hour' },
                      { value: 'more than 1 hour', text: 'Longer' },
                    ]"
                    name="register-duration"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Message *" label-for="meeting-message">
                <validation-provider
                  #default="{ errors }"
                  name="Message"
                  vid="message"
                  rules="required"
                >
                  <b-form-textarea
                    id="meeting-message"
                    v-model="meeting.message"
                    label="message"
                    name="register-message"
                    rows="5"
                    placeholder="Enter a brief message describing the purpose of the meeting"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
          </b-col>
        </b-row>

        <b-button
          type="submit"
          variant="primary"
          :disabled="invalid"
          class="float-right"
        >
          Send Request
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormRow,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BFormSelect,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
  BAlert,
  BModal,
  VBTooltip,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import api from "@/store/api/api";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    flatPickr,
    BFormRow,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BFormSelect,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    BAlert,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      dateConfig: {
        enableTime: true,
        minDate: "today",
        minTime: this.$date().format('HH:mm'),
        formatDate: (date, format, locale) => {
          return this.$date(date).format('DD MMM YYYY HH:mm')
        }
      },
      meeting: {
        title: "",
        startdate: "",
        starttime: "",
        duration: "",
        enddate: "",
        endtime: "",
        message: "",
      },

      // validation rules
      required,
    };
  },
  computed: {},
  watch: {},
  methods: {
    sendMeetingRequest() {
      // console.log('sendMeetingRequest', this.meeting);
      this.$refs.meetingRequestForm.validate().then((success) => {
        if (success) {
          // console.log('sendMeetingRequest success');
          let meeting = {
            fromBookingId: store.state.account.user.bookingNumber,
			      eventExhibitId: this.meetingRequestedWithId,
            meetingTitle: this.meeting.title,
            meetingDate: this.$date(this.meeting.startdate).toISOString(),
            meetingTime: this.meeting.starttime || '',
            meetingDuration: this.meeting.duration,
            message: this.meeting.message,
			      eventId: store.state.event.event.id
          }
          api.post('/meetings', meeting)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-left',
            props: {
              title: `Meeting Request`,
              icon: 'CalendarIcon',
              variant: 'success',
              text: `Your meeting request has been sent!`,
            },
          })
          this.$emit('closeMeetingRequestModal')
        }
      });
    },
  },
  props: {
    meetingRequestedType: {
      type: String,
      default: () => 'exhibitor',
    },
    meetingRequestedWith: {
      type: String,
      default: () => '',
    },
    meetingRequestedWithId: {
      type: String,
      required: true,
      default: () => '',
    },
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
